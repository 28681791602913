import React from "react"
import { generateUrl } from "./utils/generateUrl"
import { defaultInstallUrl } from "./utils/installUrl"
import Popup from "./Popup"
import Contents from "./Contents"
import "../styles/shiny.css"
import "../styles/App.css"

class App extends React.Component {
  state = {
    initialMount: true,
    featuresVisible: false,
    progress: 0,
    installUrl: defaultInstallUrl,
    isFreeGemsPopupOpen: false,
    isOpen: false,
  }

  eventTracker = eventSource => {
    window.fbq("trackCustom", "Login")
    window.gtag("event", "Download APK", {
      event_category: "Downloaded",
      event_label: eventSource,
      value: 1,
    })
    this.toggleModal()
    setTimeout(function () {
      var iframe = document.createElement("iframe")
      iframe.width = 640
      iframe.height = 1138
      iframe.border = 0
      iframe.allowFullScreen = true
      iframe.title = "install video"
      iframe.src =
        "https://player.vimeo.com/video/537657601?title=0&byline=0&portrait=0&autoplay=1&loop=1&background=1"

      document.getElementById("modal-wrap").appendChild(iframe)
    }, 100)

    // alert("test")
  }

  componentDidMount() {
    // generate onelink url
    let url = generateUrl(defaultInstallUrl)
    if (url && url !== " ") this.setState({ installUrl: url })

    let self = this
    window.onscroll = function () {
      if (self.state.initialMount && window.pageYOffset > 2) {
        self.setState({ featuresVisible: true, initialMount: false })
      }
    }

    // open free gems popup after 30 seconds
    const freeGemsTimer = setTimeout(() => {
      this.setState({ isFreeGemsPopupOpen: true })
    }, 30 * 1000)
    this.setState({ freeGemsTimer })
  }

  componentWillUnmount() {
    clearTimeout(this.state.freeGemsTimer)
  }

  closeFreeGemsPopup = () => {
    this.setState({ isFreeGemsPopupOpen: false })
  }

  toggleModal = () => {
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  render() {
    let { installUrl, isFreeGemsPopupOpen } = this.state

    return (
      <>
        {this.state.isOpen ? (
          <div className="custom-modal" id="modal-wrap">
            <span
              className="is-large"
              onClick={this.toggleModal}
              onKeyDown={this.toggleModal}
              role="button"
              tabIndex="0"
            >
              <strong className="mdi closeBtn">X</strong>
            </span>
          </div>
        ) : null}

        <Contents eventTracker={this.eventTracker} />

        {isFreeGemsPopupOpen && !this.state.isOpen && (
          <Popup
            closeFreeGemsPopup={this.closeFreeGemsPopup}
            installUrl={installUrl}
            fbEventAndShowModal={this.eventTracker}
          />
        )}
      </>
    )
  }
}

export default App
