import React, { Component } from "react"
import { Carousel } from "react-responsive-carousel"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const Images = graphql`
  {
    pic1: file(relativePath: { eq: "carousel/1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED)
      }
    }
    pic2: file(relativePath: { eq: "carousel/2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED)
      }
    }
    pic3: file(relativePath: { eq: "carousel/3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED)
      }
    }
    pic4: file(relativePath: { eq: "carousel/4.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED)
      }
    }
    pic5: file(relativePath: { eq: "carousel/5.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED)
      }
    }
    pic6: file(relativePath: { eq: "carousel/6.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED)
      }
    }
  }
`
export default class Carousel2 extends Component {
  render() {
    return (
      <StaticQuery
        query={Images}
        render={data => {
          return (
            <Carousel
              showThumbs={false}
              showArrows={false}
              showStatus={false}
              infiniteLoop
              autoPlay
            >
              <div className="carousel-img">
                <GatsbyImage
                  image={data.pic1.childImageSharp.gatsbyImageData}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="carousel-img">
                <GatsbyImage
                  image={data.pic2.childImageSharp.gatsbyImageData}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="carousel-img">
                <GatsbyImage
                  image={data.pic3.childImageSharp.gatsbyImageData}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="carousel-img">
                <GatsbyImage
                  image={data.pic4.childImageSharp.gatsbyImageData}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="carousel-img">
                <GatsbyImage
                  image={data.pic5.childImageSharp.gatsbyImageData}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="carousel-img">
                <GatsbyImage
                  image={data.pic6.childImageSharp.gatsbyImageData}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
            </Carousel>
          )
        }}
      />
    )
  }
}
