import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import App from '../components/App';
import '../styles/landing.css';

const IndexPage = () => (
  <Layout>
    <Seo title="Home"/>
    <App/>
  </Layout>
)

export default IndexPage
