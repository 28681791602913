const Airtable = require("airtable")
const fetchRecords = async () => {
  let reviews = []
  let reviewsByRating = {}
  const base = new Airtable({ apiKey: "keyevyMSGwXN4elpS" }).base(
    "appzs5lZNAcpHlhyA"
  )

  let result = await new Promise(resolve => {
    base("Poker LP Reviews")
      .select({
        sort: [{ field: "order" }],
        // filtering out already shown 5 static reviews
        filterByFormula: "NOT(VALUE({order}) <= 5)",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.
          records.forEach(function (element) {
            if (reviewsByRating[element.fields.stars] === undefined) {
              reviewsByRating[element.fields.stars] = []
              reviewsByRating[element.fields.stars].push(element.fields)
            } else {
              reviewsByRating[element.fields.stars].push(element.fields)
            }
            reviews.push(element.fields)
          })

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage()
        },
        function done(err) {
          if (err) {
            console.error(err)
            return
          }
          resolve({ reviews, reviewsByRating })
        }
      )
  })
  return result
}

const data = [
  {
    id: "recAAYPU9clw93sxB",
    fields: {
      name: "Jaipal Nain",
      msg: "This app is very osm. I earned a lot of money while playing poker and cashed out instantly.",
      stars: 5,
      date: "1",
      order: "1",
      likes: 154,
    },
    createdTime: "2019-08-15T16:46:31.000Z",
  },
  {
    id: "recNlHj3X6y8JvDoC",
    fields: {
      name: "Ashish jain",
      msg: "one of the best poker, wonderful app its best to timepass in college and lots of interesting games and special thanks to game editor who edit this game. well done...",
      stars: 5,
      date: "3",
      order: "2",
      likes: 183,
    },
    createdTime: "2019-08-15T16:46:31.000Z",
  },
  {
    id: "rec45EtN0SfywzhDM",
    fields: {
      name: "nishikant sathe",
      msg: "this game of poker is wonderful and very real but i request you all to make big blind tables 400 active 24hrs ",
      stars: 5,
      date: "5",
      reply:
        "Hi Nishikant,\nThank you for the suggestion, new higher tables will be opened very soon!",
      reply_date: "2",
      order: "3",
      likes: 106,
    },
    createdTime: "2019-08-15T16:46:31.000Z",
  },
  {
    id: "recajI5B9s632k4BB",
    fields: {
      name: "Krishna Dhole",
      msg: "Love there deposit offers all the time 🤩🤩🤩",
      stars: 5,
      date: "2",
      reply:
        "Hey Krishna, \nThanks for the feedback. Even better deposit offers will be launched very soon",
      reply_date: "1",
      order: "4",
      likes: 6,
    },
    createdTime: "2019-08-15T16:46:31.000Z",
  },
  {
    id: "rech31ybE7aaCz7tp",
    fields: {
      name: "shashank singh",
      msg: "Wow! I'm in love with the poker and maine kafi sara paisa jeeta ek din mein.",
      stars: 5,
      date: "4",
      order: "5",
      likes: 22,
    },
    createdTime: "2019-08-15T16:46:31.000Z",
  },
  {
    id: "recfxRfGEojbcnfqU",
    fields: {
      name: "Ravindra Thapar",
      msg: "you make a good poker, maine sare friends ko refer kara diya and money win kiya. I WANT MORE MEGA TOURNAMENTS!!",
      stars: 5,
      date: "5",
      order: "6",
      likes: 226,
    },
    createdTime: "2019-08-15T16:46:31.000Z",
  },
]

const staticRecords = data.map(obj => obj.fields)
export { fetchRecords, staticRecords }
