import React from 'react';
import ReviewCard from './ReviewCard';
import StaticReviews from './StaticReviews';
import { fetchRecords } from '../fetchAirtableRecords';
import "../styles/header.css";
import { GatsbyImage } from "gatsby-plugin-image";

class ReviewsList extends React.Component {
  state = {
    reviews: [],
    reviewsByRating: {},
    showReviewsList: false,
    initialMount: true
  }

  async componentWillReceiveProps(newProps, _) {
    if (this.state.initialMount && newProps.scrollHeight > 2) {
      let { reviews, reviewsByRating } = await fetchRecords();
      this.setState({ reviews, reviewsByRating, showReviewsList: true, initialMount: false })
    }
  }

  render() {
    let { data, reviewsVisible } = this.props;
    let { reviews, showReviewsList } = this.state;
    let reviewElements = reviews.map((review, index) => {
      return (
        <div id="reviews" key={index}>
          <ReviewCard review={review} className="marg16"/>
          <hr/>
        </div>
      )
    })
    return <>
      <hr/>
      <div className="container reviews-container">
        <p className="ratings-text">Ratings and reviews : </p>
        <StaticReviews/>
        {(showReviewsList && reviewsVisible) ? reviewElements : null}
        <div className="payments-partner">
          <div className="payments-header">
            Our Payment Partners
          </div>
          <div className="partners-image">
            <GatsbyImage image={data.partners.childImageSharp.gatsbyImageData} alt=""/>
          </div>
        </div>
        <div className="extra-review"/>
      </div>
    </>;
  }
}

export default ReviewsList;