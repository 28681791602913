import React from "react"
import ReviewsList from "./ReviewsList"
import icon from "../images/icon.svg"
import Disclaimer from "./Disclaimer"
import "../styles/landing.css"

const items = []

class Description extends React.Component {
  state = {
    listItems: items,
    moreItems: [
      "Discover India’s biggest cards tables ♠️ ❤",
      "Play with sirf 100% verified players, no fake profiles 👩 👨",
      "Choose stakes apni marzi ke - small, medium, large, & HUGE 🏆",
      "Khelo kabhi bhi, with non-stop 24-hours tables ⏳",
      "Kisi bhi issue ke liye high-priority chat support 👨🏽‍💻",
      "Support karo 100% made in India app 🇮🇳",
    ],
    itemsToShow: items,
    showingMoreItems: false,
    scrollHeight: 0,
    initialMount: true,
    progress: 0,
  }
  handleListItems = () => {
    let { showingMoreItems, listItems, moreItems } = this.state
    let totalItems
    if (!showingMoreItems) {
      totalItems = [...listItems, ...moreItems]
    } else {
      totalItems = [...listItems]
    }
    this.setState(state => {
      return {
        showingMoreItems: !state.showingMoreItems,
        itemsToShow: totalItems,
      }
    })
  }

  async componentDidMount() {
    let self = this
    window.addEventListener("scroll", function () {
      if (self.state.initialMount && window.pageYOffset > 2) {
        self.setState(
          { scrollHeight: window.pageYOffset, initialMount: false },
          () => {
            // console.log("state is set")
          }
        )
      }
      if (window.pageYOffset > 1200 && self.state.progress !== 1200) {
        // setting progress
        self.setState({ progress: 1200 })
      }
      if (window.pageYOffset < 1200 && self.state.progress !== 0) {
        // unsetting progress
        self.setState({ progress: 0 })
      }
    })
  }

  render() {
    let { showingMoreItems, itemsToShow, scrollHeight, progress } = this.state
    let { reviewsVisible, installUrl } = this.props
    let transitionClass = progress >= 1200 ? "fade-in" : "fade-out"
    let text = showingMoreItems ? "COLLAPSE" : "READ MORE"

    return (
      <>
        <div className="description-list">
          <div className="description-list-text">
            Play Cards games on India’s most loved real money entertainment
            platform. 10000+ players are playing every day. Choose your contest
            / table, small stakes or big stakes. Unlimited Real Cash to be won!
          </div>
          <ul>
            {itemsToShow.map((item, i) => {
              return <li key={i}>{item}</li>
            })}
          </ul>
          <div className="toggle-button">
            <span
              onClick={this.handleListItems}
              onKeyDown={this.handleListItems}
              role="button"
              tabIndex="0"
            >
              {text}
            </span>
          </div>
        </div>
        <Disclaimer />
        <>
          {" "}
          <ReviewsList
            reviewsVisible={reviewsVisible}
            scrollHeight={scrollHeight}
            data={this.props.data}
          />
          <div className={`sticky-container ${transitionClass}`}>
            <div id="glossy-button" className={`download`}>
              <a
                onClick={() =>
                  this.props.eventTracker("Sticky Download Button")
                }
                href={installUrl}
              >
                <div className="icon-flex">
                  <img src={icon} alt="" />
                </div>
                <div
                  className="download-text font-download"
                  style={{ fontFamily: "'Gotham', sans-serif !important" }}
                >
                  Download App
                </div>
              </a>
              <i />
            </div>
          </div>
        </>
      </>
    )
  }
}

export default Description
