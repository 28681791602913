import React from "react";

const Disclaimer = () => {
  return (
    <div>
      <hr/>
      <p
        style={{ margin: "12px 1rem" }}
        className="has-text-grey is-size-7 margBot7"
      >
        This game involves an element of financial risk and may be addictive.
        Please play responsibly and at your own risk.
      </p>
    </div>
  );
};

export default Disclaimer
